import React, {memo, useEffect, useState} from "react";
import "./Users.css";
import { getUsersPresets, deleteUsersPreset, getWorkLocations } from "../services/api";
import officeIcon from "../images/apartment_white.svg";
import homeIcon from "../images/home_white.svg";
import deleteIcon from "../images/delete_white.svg";
import dayjs from "dayjs";

export default memo(({
    selectedUsers,
    setSelectedUsers,
    users,
    token,
    invalidateUsersPresetsCounter
}) => {
    const [presets, setPresets] = useState([]);

    const [userLocations, setUserLocations] = useState({});
    useEffect(() => {
        if (!token) {
            return;
        }

        const fetch = async () => {
            const today = dayjs().format("YYYY-MM-DD");
            const wl = (await getWorkLocations(token, today, today, null)).data;
            setUserLocations(Object.assign({}, ...wl.map(w => ({
                [w.userId]: w
            }))));
        };
        const t = setInterval(fetch, 1000 * 60);
        fetch();
        return () => {
            clearInterval(t);
        };
    }, [setUserLocations, token]);

    useEffect(() => {
        if (invalidateUsersPresetsCounter && token) {
            (async () => {
                setPresets((await getUsersPresets(token)).data);
            })();
        }
    }, [token, invalidateUsersPresetsCounter]);
    return (
        <div className="users panel">
            <h2>Users</h2>
            <div className="users-grid" style={{
                gridTemplateRows: `repeat(${Math.ceil(((users ? users.length : 0) + (presets ? presets.length : 0)) / 2)}, 1fr)`
            }}>
                {users && users.filter(u => !u.disabled).sort((a, b) => {
                    const comp0 = a.firstName.localeCompare(b.firstName);
                    return comp0 === 0 ? a.lastName.localeCompare(b.lastName) : comp0;
                }).map(user => (
                    <div key={`user-${user.userId}`} className={`user${(!selectedUsers || selectedUsers.has(user.userId)) ? " selected" : ""}`} onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!selectedUsers) {
                            setSelectedUsers(new Set([user.userId]));
                        } else {
                            const newSet = new Set(selectedUsers.values());
                            if (newSet.has(user.userId)) {
                                newSet.delete(user.userId);
                            } else {
                                newSet.add(user.userId);
                            }
                            setSelectedUsers(newSet.size > 0 ? newSet : null);
                        }
                    }}>
                        {userLocations[user.userId]?.location === "Office" && (
                            <img src={officeIcon} alt="Office"/>
                        )}
                        {userLocations[user.userId]?.location === "Home" && (
                            <img src={homeIcon} alt="Home"/>
                        )}
                        {user.firstName} {user.lastName}
                    </div>
                ))}
                {presets.map(p => (
                    <div key={`preset-${p.id}`} className={"user preset"} onClick={async e => {
                        e.preventDefault();
                        e.stopPropagation();
                        let isAlreadySelected = selectedUsers != null;

                        if (selectedUsers) {
                            const setA = new Set(selectedUsers.values());
                            const setB = new Set(p.users.map(u => u.userId));
                            for (const a of setA) {
                                if (!setB.has(a)) {
                                    isAlreadySelected = false;
                                    break;
                                }
                            }
                            for (const b of setB) {
                                if (!setA.has(b)) {
                                    isAlreadySelected = false;
                                    break;
                                }
                            }
                        }
                        if (isAlreadySelected) {
                            setSelectedUsers(null);
                        } else {
                            setSelectedUsers(new Set(p.users.map(u => u.userId)));
                        }
                    }}>
                        {p.name}
                        <img src={deleteIcon} alt="Delete" className="delete-preset" disabled={!token} onClick={async e => {
                            e.stopPropagation();
                            e.preventDefault();
                            await deleteUsersPreset(token, p.id);
                            setPresets((await getUsersPresets(token)).data);
                        }}/>
                    </div>
                ))}
            </div>
        </div>
    );
});