import React, { memo, useMemo, useState } from "react";
import { deleteWorkHours, postWorkHours } from "../services/api";
import "./AdjustWorkHoursForm.css";
import saveIcon from "../images/save_white.svg";
import deleteIcon from "../images/delete_white.svg";

export default memo(({
    token,
    date,
    formattedDate,
    ownUser,
    onOk,
    workHours,
    defaultWorkHours,
    saveWorkHours,
    removeWorkHours,
    isRemovable
}) => {
    const [start, setStart] = useState(workHours?.startTime || defaultWorkHours?.startTime || "");
    const [end, setEnd] = useState(workHours?.endTime || defaultWorkHours?.endTime || "");
    const [saving, setSaving] = useState(false);

    const disabled = useMemo(() => {
        if (saving || !token) {
            return true;
        }

        if (!start || !end) {
            return true;
        }

        return start > end;
    }, [start, end, saving]);

    return (
        <div className="adjust-work-hours">
            <h2>Work hours for {formattedDate}</h2>
            <input type="time" value={start} onChange={e => {
                setStart(e.target.value);
            }}/>
            <input type="time" value={end} onChange={e => {
                setEnd(e.target.value);
            }}/>
            { isRemovable && (
                <button className="delete" onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSaving(true);
                    try {
                        await deleteWorkHours(token, date);
                        removeWorkHours(date);
                        onOk();
                    } finally {
                        setSaving(false);
                    }
                }}>
                    <img src={deleteIcon} alt=""/> Delete
                </button>
            )}
            <button className="save" onClick={async e => {
                e.preventDefault();
                e.stopPropagation();

                setSaving(true);

                try {
                    const wh = (await postWorkHours(token, {
                        userId: ownUser.userId,
                        workDate: date,
                        startTime: start,
                        endTime: end
                    })).data;
    
                    saveWorkHours(wh);
                    onOk();
                } finally {
                    setSaving(false);
                }
            }} disabled={disabled}>
                <img src={saveIcon} alt=""/> Save
            </button>
        </div>
    );
});